import Spinner from './Spinner.js'

type SpinnerFixedProps = {
  className?: string
}

const Wrapper = styled.div(`
  fixed
  m-auto
  left-0
  right-0
  top-64
  flex
  items-center
  justify-center
`)

export default function SpinnerFixed(props: SpinnerFixedProps) {
  return (
    <Wrapper {...props}>
      <Spinner className='size-6' />
    </Wrapper>
  )
}