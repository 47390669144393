import useInDevMode from './useInDevMode.js'
import useDevPromptCriterias from './useDevPromptCriterias.js'

export default function useDevPromptCriteria(name: Dx.Ai.Model.Prompt.Name) {
  const inDevMode = useInDevMode()
  const model = useDevPromptCriterias(s => {
    const prompt = s[name]

    if (prompt?.value?.selection && prompt.value.selection !== 'default') {
      return prompt.value
    }
  })
  
  return useEvent(() => inDevMode ? model : undefined)
}