import useDevPrompts from './useDevPrompts.js'

type UseDeveloperPromptOptions = {
  actions?: () => React.ReactNode
  summary?: Nullable<string> | false
}
export default function useDevPrompt(name: Dx.Ai.Model.Prompt.Name, options: UseDeveloperPromptOptions = {}) {
  const {
    actions,
    summary,
  } = options

  const {
    activate,
    deactivate,
    setActions,
    setSummary,

  } = useDevPrompts(s => ({
    activate: s.activate,
    deactivate: s.deactivate,
    setActions: s.setActions,
    setSummary: s.setSummary,
  }))


  useEffect(() => {
    activate(name)
    return () => deactivate(name)
  }, [activate, deactivate, name])

  useEffect(() => {
    setActions(name, actions)
    return () => setActions(name)
  }, [setActions, name, actions])

  useEffect(() => {
    setSummary(name, summary)
    return () => setSummary(name, null)
  }, [setSummary, name, summary])
}